import React from 'react';
import styled from 'styled-components';
import {Breakpoints} from '../../constants';
import BannerFirst from '../../../static/images/intro/ATTCINEMAS2.jpg';
import {SectionHeader} from '../Landing/SectionHeader';
import City from '../../../static/images/intro/creditcard.png';

const BusinessPage = (props) => {
  return (
    <Wrapper>
      <HeroBannerSection onClick={async (e) => {}} image={BannerFirst} />
      <div className="content">
        <SectionHeader
          title="卡友優惠"
          caption="creditcard"
          style={{marginBottom: 30}}
        />

        <img src={City} alt="credit card" className="credit-card" />
        <div className="title">ATT吸引力影城團體電影優待券【1本50張】</div>
        <div className="introduction">{`
            團體劃位: 25人以上觀賞同場次電影(該廳部分席次)，即可享有團體價格並優先劃位。
包廳活動: 依照影廳座位數全數包下整場影廳(價格=影廳座位數*團體票價)，最少22人即可包廳，開場前享有10分鐘廳內活動、迎賓桌、歡迎告示、麥克風等...頂級影廳設備也可撥放自製影片，滿足各式學校班級、公司團體員工電影欣賞、家庭日、招待客戶、行銷活動需求。

1. 請於7-10個工作天前來電預定。
2. 片長超過150分鐘或特殊節日需加價，確認後於付款前另行告知。
3. 包廳與團劃活動依現場排片為主 / 席次安排以預訂先後順序為主。
4. 團劃包廳活動為專屬優惠活動，出票後恕不退換。
5. 新片上映首週、假日與假日前一晚，需搭配可樂爆米花組合餐飲。
          `}</div>

        <div className="title">看電影集點數．雙重集賺更多</div>
        <HeroBannerSection onClick={async (e) => {}} image={BannerFirst} />
        <div className="title">卡友儲值專屬優惠</div>
        <HeroBannerSection onClick={async (e) => {}} image={BannerFirst} />
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  min-height: calc(100vh - var(--topNavBarHeight));
  & > .content {
    & > img.credit-card {
      width: 300px;
      height: 200px;
      object-fit: contain;
    }
    max-width: var(--contentMaxWith);
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    & > .title {
      font-weight: bold;
      margin-bottom: 30px;
    }
    & > .introduction {
      font-size: 13px;
      color: #000000;
      white-space: pre-line;
      margin-bottom: 50px;
    }
  }

  @media screen and (max-width: ${Breakpoints.md}px) {
    & > .content {
      & > img.credit-card {
        width: 100%;
      }
      padding: 50px 20px;
    }
  }
`;

const HeroBannerSection = styled.section`
  padding: 80px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: ${({image}) => `url(${image})`};
  background-position: center;
  background-size: cover;
  background-color: orange;
  & > h2 {
    font-size: 32px;
    color: white;
  }
  & > p {
    font-size: 18px;
    color: #ccc;
  }

  @media screen and (max-width: ${Breakpoints.sm}px) {
    min-height: 180px;
  }
  @media screen and (max-width: ${Breakpoints.md}px) {
    min-height: 300px;
  }
`;

export default BusinessPage;
